<template>
  <v-dialog
    v-model="open"
    :max-width="width"
    scrollable
    persistent
    no-click-animation
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        fab
        small
        class="mt-n5"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>
          fal fa-plus
        </v-icon>
      </v-btn>
    </template>
    <lab-result-form
      v-if="open"
      @cancel="open = false"
      @pdf-uploaded="width = '95%'"
      @lab-result-added="$emit('lab-result-added', $event); open = !$event"
    />
  </v-dialog>
</template>
<script>
export default {
  components: {
    LabResultForm: () => import('@/components/lab_result/LabResultForm.vue'),
  },
  data: () => ({
    open: false,
    width: 1150,
  }),
  watch: {
    open: function() {
      if (!this.open) {
        this.width = 950
      }
    },
  },
}
</script>
